@import "bootstrap/bootstrap";

/* https://getbootstrap.com/docs/4.0/examples/sticky-footer-navbar/ */

/* Sticky footer styles
-------------------------------------------------- */
html {

  position: relative;
  min-height: 100%;

}

body {

  /* Margin bottom by footer height */
  margin-bottom: 60px;

}

.footer {

  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;

}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container-fluid {

  /* padding: 60px 15px 0; */

}

.footer > .container-fluid {

  padding-right: 15px;
  padding-left: 15px;

  a {
    color: white;
  }

}

code {

  font-size: 80%;

}

div#airporthub {

  background-image: url( "../assets/img/airport-hub-no-text.1280x750.jpg" );
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  min-height: 960px;
  padding-top: 20px;
  /* margin-top: -60px; */

}

#our-goal {

  // color: white;

  background-image: url( "../assets/img/our-goal-2000x1200x100.png" );
  background-size: cover;
  // background-color: teal;

  min-height: 200px;

  margin-top: 70px;
  margin-bottom: 35px;

  padding-left: 2em;
  padding-right: 2em;
  padding-top: 40px;
  padding-bottom: 40px;

  border: 2px solid #cccccc;
  // border-radius: 12px;

  text-align: center;

  a {
    font-size: x-large;
    color: black;
  }

  p {
    margin-bottom: 6px;
  }

}

#the-why {

  margin-top: 25px;

  padding-top: 70px;
  padding-bottom: 70px;

  background-color: #eeeeee;

}

#documents-for-import,
#apis-for-import,
#acquisition-target {

  padding-top: 70px;
  padding-bottom: 70px;

  background-color: #eeeeee;

}

#electronically-deliver,
#scan-qr,
#no-host-ofx,
#learn-more {

  padding-top: 70px;
  padding-bottom: 70px;

  background-color: #fff;

}

pre {

  border: 1px solid #ccc;

  border-radius: 12px;

  padding: 1.5em;

  font-size: 14px;

  color: white;

  background-color: black;

}

.card-itips-1 {

  // #234296
  // rgb( 0, 0, 0 )
  background-color: rgba( 22, 72, 128, 0.95 );
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

}

.card-itips-2 {

  background-color: rgba( 23, 108, 138, 0.95 );
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

}

.card-itips-3 {

  background-color: rgba( 25, 115, 115, 0.95 );
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

}

.card-itips-4 {

  background-color: rgba( 23, 138, 105, 0.95 );
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

}

.card-itips-5 {

  background-color: rgba( 22, 128, 68, 0.95 );
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

}

button {
  border-radius: 0;
}

hr {
  border: none;
  height: 3px;
  background-color: teal; /* Modern Browsers */
}

.quadrant {

  border: 1px solid #ccc;

  border-radius: 20px;

  padding: 2em;

}

.card {

  padding: 2em;

}

